import { Poppins, Quicksand } from 'next/font/google';
import Image from 'next/image';
import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { FaRegUser } from "react-icons/fa";
const popins = Poppins({ weight: "400", subsets: ["latin"],display:'swap'});
const quickSand = Quicksand({ weight: "400", subsets: ["latin"],display:'swap'});
const extrapopins = Poppins({ weight: "500", subsets: ["latin"],display:'swap'});



export default function TestimonialSlide({TestiData}) {


const settings = {
  dots: true,
  speed: 500,
  slidesToShow: 3,
  infinite: true,
  slidesToScroll: 1,
  arrows:false,
  customPaging: (i) => (
    <div className="slick-dot" key={i}>
      <style jsx>
        {`
          .slick-dot {
               width: 19px;
            height: 10px;
            background-color: #FFEDD1;
            border-radius: 10px;
            margin: 1px 5px;
            cursor: pointer;
            display: inline-block;
            margin-bottom:-3px;
          }
      
 .slick-dots li {
          background-color: black !important;
        }

          @media only screen and (max-width: 1023px){
            .slick-dot {
              width: 19px;
              height: 10px;
              background-color: #FFEDD1; /* Default color */
              border-radius: 10px;
              margin: 1px 5px;
              cursor: pointer;
              display: inline-block;
              margin-bottom:30px;
            }
          }
        `}
      </style>
    </div>
  ), 
   
  responsive: [
    {
      breakpoint: 1223,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        dots: true,
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
      }
    },
    
  ]
}; 


  return (
    <>
<section className='testi-bg mt70 mmt40'> 
<div className='container'>

              <h2 className={`fs-40  mfs-24 tac ${extrapopins.className}`}>Testimonials</h2>

              <h3 className={`fs-28 mfs-19 tac mt20 ${extrapopins.className}`}>What Our Students Say 
              <br/><span className={`ylw-clr ${extrapopins.className}`}>About Us & Our Services Master Courses</span> </h3>



{/* Box */}
<Slider {...settings}>


 {TestiData.map((items,index)=>{
        return(
          <div className='col mt32' key={index}>
          <div className='df fjse'>
        <div className='testi-box ' >
 <div className='df fjsb'>         
<div className='df fac gap11'>
<div className='img'>
<FaRegUser className='pd8 cw tac' size={26}/>
</div>
<div className=''>
  <p className={`fs-16 mfs-12 ${popins.className}`}>{items.UserName}</p>
  <p className={`fs-12 dim-clr mt4 mfs-9 mmt2 ${extrapopins.className}`}>{items.Course}</p>
</div>

</div>
<div className=''>
    <Image src={"/testimonial-img/rating-star.webp"} alt='Rating' width={"88"} height={"16"} loading='lazy'/>
  </div>

</div>
<div className='df fjc pdt6 fdc'>
<p className={`fs-14 mfs-10 hvz ${quickSand.className}`}>{items.review}</p>


</div>

</div>


</div></div> )
      })}</Slider></div> 
</section>
<style jsx>
{`


    .col {
      width: 100%; // Set the width to 100% for smaller screens
      margin-bottom: 52px;
      margin-left: auto;
      margin-right: auto; // Center the slider on the screen
    }

.testi-bg{

// background-color: #FEEAC3;
width: 100%;
overflow: hidden;

}
.img{
background: #FFA900;
border-radius: 50px;
}
.testi-box{
    width: 100%;
    height: 222px;
    border-radius: 16px;
  background: linear-gradient(to right, rgba(255, 169, 0, 0.3), rgba(255, 207, 200, 0.5));
    cursor: pointer;
    margin:0px 15px;
    padding:32px 20px 10px 20px;
}


@media only screen and (max-width: 768px){
   .testi-box{
            width: 100% !important;
            height: auto !important;
            border-radius: 12.2px;
              background: linear-gradient(to right, rgba(255, 169, 0, 0.3), rgba(255, 207, 200, 0.5));

        }


}

@media only screen and (max-width: 1023px){
    .testi-bg{
        width: 100%;     
        }
        .testi-box{
            width: 280px;
            height: 190.62px;
            border-radius: 12.2px;
              background: linear-gradient(to right, rgba(255, 169, 0, 0.3), rgba(255, 207, 200, 0.5));

        }

    h2{
    font-size: 32px;
    line-height: 38px;
    }

}
`}

</style>

</>
  )
}
