import { Poppins, Quicksand } from 'next/font/google';
import Image from 'next/image';
import Link from 'next/link';
import React, { useState } from 'react'
import Form from '../education-form';
const popins = Poppins({weight:"400",subsets:['latin'],})
const popinsfw = Poppins({weight:"500",subsets:['latin'],})
const extrapopins = Poppins({weight:"600", subsets:['latin'],});
const quickSand = Quicksand({weight:"400",subsets:['latin'],})
export default function BestItTraining() {

const [form,setForm]= useState(false);

const FormPopup = ()=>{
  setForm(true);
}

const CloseFormPopup = ()=>{
  setForm(false);
}

  return (
    <>
    <section className='container'>
        <div className='df  gap20 bg tfdc mt70 mmt40'>
           {/* Images Div */}
        <div className='pos-r img-width df fjfe'>
            <Image  className='mdn' src={"/home-v2-images/think-img.webp"} width={415} height={511} alt='Best-Institute-Learnsoft' loading='lazy'/>
            <Image className='dn mdb' src={"/home-v2-images/think-img.webp"} style={{width:'100%',height:'auto'}} width={480} height={511} alt='Best-Institute-Learnsoft' loading='lazy'/>

           <div className='explain-img'>
           <Image className='mdn' src={"/home-v2-images/explain.webp"} width={262} height={252} alt='Best-Institute-Learnsoft' loading='lazy'/>
           <Image className='dn mdb' src={"/home-v2-images/explain.webp"} width={152} height={160} alt='Best-Institute-Learnsoft' loading='lazy'/>

           </div>
           <div className="experience-box  df fac fjc">
  <p className={`tac fs-16 cw ${extrapopins.className}`}>6+ <br/> years of Experience</p>
</div>
        </div>
                              {/* Content Div */}

             <div className='content-container w40p'>
               <h1 className={`fs-33 mfs-25 ${extrapopins.className}`}>Software Training Institute In Chennai to upskill  your tech domain</h1>
              <p className={`fs-19 mfs-16 mt32 mmt20 ${quickSand.className}`}>Our institute is a leading destination for IT training, designed to help you enhance your technical skills and stay competitive. With expert guidance and hands-on learning, we empower you to excel in your tech career</p>
                 <div className='df  mt32 mfw fjs gap20 mfji ' >
                    <div className='df fdc '>
                <div className='df gap20 fac'>
                     <Image  src="/home-v2-images/best-training-icons/learn.svg" width={40} height={40} alt='Course-Type-Icon' loading='lazy'/>
                     <p className={`fwb fs-16 mfs-14 ${popins.className}`}>Learn More Anywhere</p> 
                 </div> 
                 <div className='df gap20 fac mt32 mmt10'>
                     <Image  src="/home-v2-images/best-training-icons/monitor.svg" width={40} height={40} alt='Certificate-Icon' loading='lazy'/>
                     <p className={`fs-16 mfs-14 fwb ${popins.className}`}>Teaching Monitoring</p> 
                 </div>   
                 </div>

                 <div className='df fdc '>
                <div className='df gap20 fac'>
                     <Image  src="/home-v2-images/best-training-icons/expert.svg" width={40} height={40} alt='Trainer-Icon' loading='lazy'/>
                     <p className={`fs-16 mfs-14 fwb ${popins.className}`}>Expert Instructor</p> 
                 </div> 
                 <div className='df gap20 fac mt32 mmt10'>
                     <Image  src="/home-v2-images/best-training-icons/support.svg" width={40} height={40} alt='Time-Access-Icon' loading='lazy'/>
                     <p className={`fs-16 mfs-14 fwb ${popins.className}`}>24/7 Strong Support</p> 
                 </div>   
                 </div>

                 </div>
                  <button onClick={FormPopup} className='enrl-btn fs-19 mfs-16 pdtb10 cw mt32 mmt20 cursor'>Enroll Now</button>
                </div>

        </div>
    </section>
    {form &&<Form close={CloseFormPopup}/> }
    <style jsx>
        {`
        .img-width{
        width: 50%;
        }
        .bg{
        height: auto;
        }
     .enrl-btn{
        background-color: #FDBB39;
        border-radius: 8px;
       width: 295px;
       border:none;

        }
.experience-box {
 position: absolute;
 top: 5%;
 left: 5%;
  background-color: #FDBB39; /* Similar yellow color */
  border-radius: 20px;
  width: 194px; 
  height: 161px; 
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  transform: skewX(3deg); 
  border: 5px solid #FFFFFF;
}
.explain-img{
position: absolute;
top: 39%;
left: 3%;
}
 .content-container {
              width: 50%;
            }

@media only screen and (min-width: 768px) and (max-width: 1023px) {
h1{
font-size: 40px !important;
line-height: 38px !important;
}
}
@media only screen and (max-width: 1023px){

.experience-box {
 position: absolute;
 top: 5%;
 left: -45%;
  background-color: #FDBB39; /* Similar yellow color */
  border-radius: 11px;
  width: 130px; 
  height: 70px; 
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  transform: skewX(3deg); 
  border: 2px solid #FFFFFF;
}
  .explain-img{
position: absolute;
top: 39%;
left: -55%;
}
}     

@media only screen and (max-width: 450px){
.experience-box {
 position: absolute;
 top: 5%;
 left: -40%;
  background-color: #FDBB39; /* Similar yellow color */
  border-radius: 20px;
  width: 100px; 
  height: 84px; 
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  transform: skewX(3deg); 
  border: 3px solid #FFFFFF;
}
  .explain-img{
position: absolute;
top: 39%;
left: -50%;
}
}
@media only screen and (max-width: 420px){

.experience-box {
 position: absolute;
 top: 5%;
 left: -25%;
  background-color: #FDBB39; /* Similar yellow color */
  border-radius: 20px;
  width: 100px; 
  height: 84px; 
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  transform: skewX(3deg); 
  border: 3px solid #FFFFFF;
}
  .explain-img{
position: absolute;
top: 39%;
left: -25%;
}
}
        @media only screen and (max-width:1250px){
              .tfdc{
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      }
         .enrl-btn{
        background-color: #FDBB39;
        border-radius: 8px;
       width: 290px;
       border:none;

        }
        }
        @media only screen and (max-width: 1250px){
              .tfdc{
              display: flex;
              flex-direction: column;
              align-items: center;
              }
    .img-width{
        width: auto;
        }
    .content-container {
              width: auto;
            }
        }
        `}
    </style>
    </>
  )
}
