import { Poppins, Quicksand } from 'next/font/google';
import Image from 'next/image'
import React from 'react'
const popins = Poppins({weight:"400",subsets:['latin'],})
const popinsfw = Poppins({weight:"500",subsets:['latin'],})
const extrapopins = Poppins({weight:"600", subsets:['latin'],});
const quickSand = Quicksand({weight:"400",subsets:['latin'],})
export default function BenefitsOfJoining() {

    const BenefitsData = [
        {
            heading:"Industry-Expert Instructors",
            cnt:"Gain insights and practical knowledge directly from experts currently working in the field"
        },
        {
            heading:"Hands-on Training",
            cnt:"Engage in immersive, hands-on training sessions that emphasize practical application of skill"
        },
        {
            heading:"Comprehensive Course Offerings",
            cnt:"Choose from a wide range of cutting-edge courses in Programming, digital marketing, Networking, Data Science, and more"
        },
        {
            heading:"Flexible Learning Options",
            cnt:"Enjoy flexible learning formats, including in-person, online, and hybrid classes to suit your schedul"
        },
    ]

  return (
    <>
    <section className='benefits-bg pdtb70 mpdtb20'>
        <div className='container'>
          <div className='df tfdc '>
            <div className='w60p twap'>
          <h2 className={`fs-40 mfs-24 ${extrapopins.className}`}>Benefits of Joining  <span className='ylw-clr'> Learnsoft  </span></h2>
          <div className='mt32 mmt10 df fw gap20 mfjc '>
            {BenefitsData.map((items,index)=>{
                return(
            <div className='benefits-box pd10 mt20 mmt10' key={index}>
              <p className={`fs-16 ${popinsfw.className}`}>{items.heading}</p>
              <p className={`fs-14 dim-clr mt20 ${quickSand.className}`}>{items.cnt}</p>
            </div>
             )})}
          </div>
          </div>
          <div className='mmt20'>
           <Image  style={{width:'100%',height:'auto'}} className='' src={"/home-v2-images/benefits-join-img.webp"} width={538} height={572} alt='Benefits-img' loading='lazy'/>
           {/* <Image layout='responsive' className='dn mdb' src={"/home-v2-images/benefits-join-img.webp"} width={490} height={372} alt='Benefits-img' loading='lazy'/> */}

          </div>
          </div>
        </div>
    </section>
    <style jsx>
        {`
        .benefits-bg{
            background-image: url('/home-v2-images/benefits-bg.webp');
// background: linear-gradient(90deg, #FFDD99 0%, #FFDD99 10%, #FFFFFF 80%, #FFFFFF 100%);


            width:100%;
            height: auto;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            background-position: cover;
            background-size: 100% 100%;
        }
            .benefits-box{
            background-color: #FFFFFF;
            border-radius: 10px;
            width: 295px;
            height: 178px;
            
            }

@media only screen and (min-width: 768px) and (max-width: 1023px){
    h2{
    font-size: 32px;
    line-height: 38px;
    }
   }
        @media only screen and (max-width: 1250px){
              .tfdc{
              display: flex;
              flex-direction: column;
              align-items: center;
              }
              .twap{
              width: auto;
              }
            .benefits-bg{
            background-image: url('/home-v2-images/benefits-tab-bg.webp');
            width:100%;
            height: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            background-position: cover;
            background-size: 100% 100%;
        }
        }
        `}
    </style>
    </>
  )
}
